<template>
  <div id="main">
    <div id="banner">
      <img class="banner-bg" :src='require("@/assets/img/technology/banner-bg.png")'>
    </div>
    <div id="detail">
      <div class="detail-item stim" ref="stim">
        <img class="img" :src='require("@/assets/img/technology/stim-img.png")'
          :style='{left: stim.img.left+"rem", opacity: stim.opacity}'>
        <div class="info" :style='{left: stim.info.left+"rem", opacity: stim.opacity}'>
          <img class="logo" :src='require("@/assets/img/technology/stim-logo.png")'>
          <p class="title">EMS电刺激技术<br />修复肌肉功能</p>
          <p class="subTitle">增强肌肉收缩 激发本体感觉</p>
          <div class="vi-symbol symbol"></div>
          <p class="desc">应用低频脉冲电流刺激激活受损神经和肌肉，恢复其功能；在增强肌肉收缩力的同时具有恢复本体感觉、镇痛舒缓的作用。</p>
        </div>
      </div>
      <div class="detail-item emg" ref="emg">
        <img class="img" :src='require("@/assets/img/technology/emg-img.png")'
          :style='{left: emg.img.left+"rem", opacity: emg.opacity}'>
        <div class="info" :style='{left: emg.info.left+"rem", opacity: emg.opacity}'>
          <img class="logo" :src='require("@/assets/img/technology/emg-logo.png")'>
          <p class="title">肌电生物反馈技术<br />精准感知盆底活动</p>
          <p class="subTitle">增强肌力 提升控制力</p>
          <div class="vi-symbol symbol"></div>
          <p class="desc">通过阴道电极采集盆底肌收缩的肌电信号，将其转化为图形，指导用户正确用力，增强盆底肌收缩协调性和稳定性。</p>
        </div>
      </div>
      <div class="detail-item pressure" ref="pressure">
        <img class="img" :src='require("@/assets/img/technology/pressure-img.png")'
          :style='{left: pressure.img.left+"rem", opacity: pressure.opacity}'>
        <div class="info" :style='{left: pressure.info.left+"rem", opacity: pressure.opacity}'>
          <img class="logo" :src='require("@/assets/img/technology/pressure-logo.png")'>
          <p class="title">压力生物反馈技术<br />可视化盆底活动</p>
          <p class="subTitle">降低肌张力 促进主动训练</p>
          <div class="vi-symbol symbol"></div>
          <p class="desc">通过压力探头检测盆底肌肉压力信号变化，引导用户进行主动收缩锻炼，强化盆底肌力，牵张放松训练盆底肌。</p>
        </div>
      </div>
      <div class="detail-item ai" ref="ai">
        <img class="img" :src='require("@/assets/img/technology/ai-img.png")'
          :style='{left: ai.img.left+"rem", opacity: ai.opacity}'>
        <div class="info" :style='{left: ai.info.left+"rem", opacity: ai.opacity}'>
          <img class="logo" :src='require("@/assets/img/technology/ai-logo.png")'>
          <p class="title">AI大数据技术<br />定制专属方案</p>
          <p class="subTitle">千人千面 专属方案</p>
          <div class="vi-symbol symbol"></div>
          <p class="desc">根据不同的盆底受损程度，推送AI定制化方案，智能生成专属的治疗参数，满足个性化修复需求。</p>
        </div>
      </div>
      <div class="detail-item pattern" ref="pattern">
        <img class="img" :src='require("@/assets/img/technology/pattern-img.png")'
          :style='{left: pattern.img.left+"rem", opacity: pattern.opacity}'>
        <div class="info" :style='{left: pattern.info.left+"rem", opacity: pattern.opacity}'>
          <img class="logo" :src='require("@/assets/img/technology/pattern-logo.png")'>
          <p class="title">八大训练模式<br />赋能科学治疗体系</p>
          <p class="subTitle">丰富有趣 科学修复</p>
          <div class="vi-symbol symbol"></div>
          <p class="desc">“主被动结合”的科学治疗体系，训练模式丰富多样，趣味十足。疗程化方案科学编排，循序渐进，稳步修复。</p>
        </div>
      </div>
      <div class="detail-item monitor" ref="monitor">
        <img class="img" :src='require("@/assets/img/technology/monitor-img.png")'
          :style='{left: monitor.img.left+"rem", opacity: monitor.opacity}'>
        <div class="info" :style='{left: monitor.info.left+"rem", opacity: monitor.opacity}'>
          <img class="logo" :src='require("@/assets/img/technology/monitor-logo.png")'>
          <p class="title">智能监测系统<br />助力安心训练</p>
          <p class="subTitle">安全提醒 辅助训练</p>
          <div class="vi-symbol symbol"></div>
          <p class="desc">集合智能传感技术、智能数据算法，监测智能硬件与肌肉状态，实时反馈引导，引导正确训练；及时提示预警，保护安全训练。</p>
        </div>
      </div>
      <div class="detail-item ota" ref="ota">
        <img class="img" :src='require("@/assets/img/technology/ota-img.png")'
          :style='{left: ota.img.left+"rem", opacity: ota.opacity}'>
        <div class="info" :style='{left: ota.info.left+"rem", opacity: ota.opacity}'>
          <img class="logo" :src='require("@/assets/img/technology/ota-logo.png")'>
          <p class="title">OTA技术<br />畅享一键迭代</p>
          <p class="subTitle">摆脱空间限制 简单高效远程固件升级</p>
          <div class="vi-symbol symbol"></div>
          <p class="desc">将高端新能源汽车升级技术应用于家用设备，通过OTA远程升级方式（Over-the-Air），实现一键功能进化，提供便捷、实用的智能交互体验。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scrollTop: 0,
      scrollDirection: '',
      interval: {
        time: 10,
        distance: .05,
        opacity: 1 / 50,
      },
      stim: {
        direction: '',
        img: {start: 12.14, end: 9.22, left: 12.14},
        info: {start: .68, end: 3.6, left: .68},
        opacity: 0,
      },
      emg: {
        direction: '',
        img: {start: .68, end: 4.3, left: .68},
        info: {start: 15.12, end: 11.5, left: 15.12},
        opacity: 0,
      },
      pressure: {
        direction: '',
        img: {start: 12.72, end: 9.8, left: 12.72},
        info: {start: .68, end: 3.6, left: .68},
        opacity: 0,
      },
      ai: {
        direction: '',
        img: {start: .68, end: 4.05, left: .68},
        info: {start: 14.87, end: 11.5, left: 14.87},
        opacity: 0,
      },
      pattern: {
        direction: '',
        img: {start: 12.09, end: 9.17, left: 12.09},
        info: {start: .68, end: 3.6, left: .68},
        opacity: 0,
      },
      monitor: {
        direction: '',
        img: {start: .68, end: 3.6, left: .68},
        info: {start: 14.42, end: 11.5, left: 14.42},
        opacity: 0,
      },
      ota: {
        direction: '',
        img: {start: 12.07, end: 9.15, left: 12.07},
        info: {start: .68, end: 3.6, left: .68},
        opacity: 0,
      },
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    // 让第一屏内容展示出来
    this.animationStim('down')
    if (document.documentElement.clientWidth < 1665) {
      this.stim.img.left = this.stim.img.end
      this.stim.info.left = this.stim.info.end
      this.stim.opacity = 1

      this.emg.img.left = this.emg.img.end
      this.emg.info.left = this.emg.info.end
      this.emg.opacity = 1

      this.pressure.img.left = this.pressure.img.end
      this.pressure.info.left = this.pressure.info.end
      this.pressure.opacity = 1

      this.ai.img.left = this.ai.img.end
      this.ai.info.left = this.ai.info.end
      this.ai.opacity = 1

      this.pattern.img.left = this.pattern.img.end
      this.pattern.info.left = this.pattern.info.end
      this.pattern.opacity = 1

      this.monitor.img.left = this.monitor.img.end
      this.monitor.info.left = this.monitor.info.end
      this.monitor.opacity = 1

      this.ota.img.left = this.ota.img.end
      this.ota.info.left = this.ota.info.end
      this.ota.opacity = 1
    }
    window.addEventListener('resize', () => {
      if (document.documentElement.clientWidth < 1665) {
        this.stim.img.left = this.stim.img.end
        this.stim.info.left = this.stim.info.end
        this.stim.opacity = 1

        this.emg.img.left = this.emg.img.end
        this.emg.info.left = this.emg.info.end
        this.emg.opacity = 1

        this.pressure.img.left = this.pressure.img.end
        this.pressure.info.left = this.pressure.info.end
        this.pressure.opacity = 1

        this.ai.img.left = this.ai.img.end
        this.ai.info.left = this.ai.info.end
        this.ai.opacity = 1

        this.pattern.img.left = this.pattern.img.end
        this.pattern.info.left = this.pattern.info.end
        this.pattern.opacity = 1

        this.monitor.img.left = this.monitor.img.end
        this.monitor.info.left = this.monitor.info.end
        this.monitor.opacity = 1

        this.ota.img.left = this.ota.img.end
        this.ota.info.left = this.ota.info.end
        this.ota.opacity = 1
      } else {
        this.bindScrollWindow(window.scrollY)
      }
    }, false)
  },
  methods: {
    /**
     监听页面滚动条
     @param
     @return
     */
    bindScrollWindow(scrollTop) {
      if (scrollTop > this.scrollTop) {
        this.scrollDirection = 'down'
      } else {
        this.scrollDirection = 'up'
      }
      this.scrollTop = scrollTop
      this.bindScrollStim()
      this.bindScrollEmg()
      this.bindScrollPressure()
      this.bindScrollAi()
      this.bindScrollPattern()
      this.bindScrollMonitor()
      this.bindScrollOta()
    },
    /**
     监听页面滚动条-EMS电刺激技术
     @param
     @return
     */
    bindScrollStim() {
      if (this.scrollTop > 0) {
        if (this.scrollDirection == 'down') {
          if (this.stimInterval == undefined) {
            this.animationStim('down')
          }
        }
      }
    },
    /**
     监听页面滚动条-肌电反馈
     @param
     @return
     */
    bindScrollEmg() {
      if (this.scrollTop > this.getAnimationTop('emg')) {
        if (this.scrollDirection == 'down') {
          if (this.emg.direction == 'up') {
            clearInterval(this.emgInterval)
            this.emgInterval = undefined
          }
          if (this.emgInterval == undefined) {
            this.animationEmg('down')
          }
        }
      } else {
        if (this.scrollDirection == 'up') {
          if (this.emg.direction == 'down') {
            clearInterval(this.emgInterval)
            this.emgInterval = undefined
          }
          if (this.emgInterval == undefined) {
            this.animationEmg('up')
          }
        }
      }
    },
    /**
     监听页面滚动条-压力反馈
     @param
     @return
     */
    bindScrollPressure() {
      if (this.scrollTop > this.getAnimationTop('pressure')) {
        if (this.scrollDirection == 'down') {
          if (this.pressure.direction == 'up') {
            clearInterval(this.pressureInterval)
            this.pressureInterval = undefined
          }
          if (this.pressureInterval == undefined) {
            this.animationPressure('down')
          }
        }
      } else {
        if (this.scrollDirection == 'up') {
          if (this.pressure.direction == 'down') {
            clearInterval(this.pressureInterval)
            this.pressureInterval = undefined
          }
          if (this.pressureInterval == undefined) {
            this.animationPressure('up')
          }
        }
      }
    },
    /**
     监听页面滚动条-AI大数据技术
     @param
     @return
     */
    bindScrollAi() {
      if (this.scrollTop > this.getAnimationTop('ai')) {
        if (this.scrollDirection == 'down') {
          if (this.ai.direction == 'up') {
            clearInterval(this.aiInterval)
            this.aiInterval = undefined
          }
          if (this.aiInterval == undefined) {
            this.animationAi('down')
          }
        }
      } else {
        if (this.scrollDirection == 'up') {
          if (this.ai.direction == 'down') {
            clearInterval(this.aiInterval)
            this.aiInterval = undefined
          }
          if (this.aiInterval == undefined) {
            this.animationAi('up')
          }
        }
      }
    },
    /**
     监听页面滚动条-八大训练模式
     @param
     @return
     */
    bindScrollPattern() {
      if (this.scrollTop > this.getAnimationTop('pattern')) {
        if (this.scrollDirection == 'down') {
          if (this.pattern.direction == 'up') {
            clearInterval(this.patternInterval)
            this.patternInterval = undefined
          }
          if (this.patternInterval == undefined) {
            this.animationPattern('down')
          }
        }
      } else {
        if (this.scrollDirection == 'up') {
          if (this.pattern.direction == 'down') {
            clearInterval(this.patternInterval)
            this.patternInterval = undefined
          }
          if (this.patternInterval == undefined) {
            this.animationPattern('up')
          }
        }
      }
    },
    /**
     监听页面滚动条-智能检测系统
     @param
     @return
     */
    bindScrollMonitor() {
      if (this.scrollTop > this.getAnimationTop('monitor')) {
        if (this.scrollDirection == 'down') {
          if (this.monitor.direction == 'up') {
            clearInterval(this.monitorInterval)
            this.monitorInterval = undefined
          }
          if (this.monitorInterval == undefined) {
            this.animationMonitor('down')
          }
        }
      } else {
        if (this.scrollDirection == 'up') {
          if (this.monitor.direction == 'down') {
            clearInterval(this.monitorInterval)
            this.monitorInterval = undefined
          }
          if (this.monitorInterval == undefined) {
            this.animationMonitor('up')
          }
        }
      }
    },
    /**
     监听页面滚动条-OTA技术
     @param
     @return
     */
    bindScrollOta() {
      if (this.scrollTop > this.getAnimationTop('ota')) {
        if (this.scrollDirection == 'down') {
          if (this.ota.direction == 'up') {
            clearInterval(this.otaInterval)
            this.otaInterval = undefined
          }
          if (this.otaInterval == undefined) {
            this.animationOta('down')
          }
        }
      } else {
        if (this.scrollDirection == 'up') {
          if (this.ota.direction == 'down') {
            clearInterval(this.otaInterval)
            this.otaInterval = undefined
          }
          if (this.otaInterval == undefined) {
            this.animationOta('up')
          }
        }
      }
    },

    /**
     获取动画距顶距离
     @param {String} type 动画类型
     @return {Number} 动画距顶距离
     */
    getAnimationTop(type) {
      const baseTop = 7.28 * document.documentElement.clientWidth / 1920 * 100 - document.documentElement.clientHeight
      if (type == 'emg') {
        return baseTop + this.$refs.emg.offsetTop + this.$refs.emg.offsetHeight / 2
      } else if (type == 'pressure') {
        return baseTop + this.$refs.pressure.offsetTop + this.$refs.pressure.offsetHeight / 2
      } else if (type == 'ai') {
        return baseTop + this.$refs.ai.offsetTop + this.$refs.ai.offsetHeight / 2
      } else if (type == 'pattern') {
        return baseTop + this.$refs.pattern.offsetTop + this.$refs.pattern.offsetHeight / 2
      } else if (type == 'monitor') {
        return baseTop + this.$refs.monitor.offsetTop + this.$refs.monitor.offsetHeight / 2
      } else if (type == 'ota') {
        return baseTop + this.$refs.ota.offsetTop + this.$refs.ota.offsetHeight / 2
      }
      return 0
    },

    /**
     执行动画--EMS电刺激技术
     @param {String} direction 页面滚动方向
     @return
     */
    animationStim(direction) {
      this.stim.direction = direction
      if (direction == 'down') {
        this.stimInterval = setInterval(() => {
          if (this.stim.img.left <= this.stim.img.end) {
            clearInterval(this.stimInterval)
            return
          }
          this.stim.img.left -= this.interval.distance
          this.stim.info.left += this.interval.distance
          this.stim.opacity += this.interval.opacity
        }, this.interval.time)
      } else if (direction == 'up') {
        this.stimInterval = setInterval(() => {
          if (this.stim.img.left >= this.stim.img.start) {
            clearInterval(this.stimInterval)
            return
          }
          this.stim.img.left += this.interval.distance
          this.stim.info.left -= this.interval.distance
          this.stim.opacity -= this.interval.opacity
        }, this.interval.time)
      }
    },
    /**
     执行动画--肌电反馈
     @param {String} direction 页面滚动方向
     @return
     */
    animationEmg(direction) {
      this.emg.direction = direction
      if (direction == 'down') {
        this.emgInterval = setInterval(() => {
          if (this.emg.img.left >= this.emg.img.end) {
            clearInterval(this.emgInterval)
            return
          }
          this.emg.img.left += this.interval.distance
          this.emg.info.left -= this.interval.distance
          this.emg.opacity += this.interval.opacity
        }, this.interval.time)
      } else if (direction == 'up') {
        this.emgInterval = setInterval(() => {
          if (this.emg.img.left <= this.emg.img.start) {
            clearInterval(this.emgInterval)
            return
          }
          this.emg.img.left -= this.interval.distance
          this.emg.info.left += this.interval.distance
          this.emg.opacity -= this.interval.opacity
        }, this.interval.time)
      }
    },
    /**
     执行动画--压力反馈
     @param {String} direction 页面滚动方向
     @return
     */
    animationPressure(direction) {
      this.pressure.direction = direction
      if (direction == 'down') {
        this.pressureInterval = setInterval(() => {
          if (this.pressure.img.left <= this.pressure.img.end) {
            clearInterval(this.pressureInterval)
            return
          }
          this.pressure.img.left -= this.interval.distance
          this.pressure.info.left += this.interval.distance
          this.pressure.opacity += this.interval.opacity
        }, this.interval.time)
      } else if (direction == 'up') {
        this.pressureInterval = setInterval(() => {
          if (this.pressure.img.left >= this.pressure.img.start) {
            clearInterval(this.pressureInterval)
            return
          }
          this.pressure.img.left += this.interval.distance
          this.pressure.info.left -= this.interval.distance
          this.pressure.opacity -= this.interval.opacity
        }, this.interval.time)
      }
    },
    /**
     执行动画--AI大数据
     @param {String} direction 页面滚动方向
     @return
     */
    animationAi(direction) {
      this.ai.direction = direction
      if (direction == 'down') {
        this.aiInterval = setInterval(() => {
          if (this.ai.img.left >= this.ai.img.end) {
            clearInterval(this.aiInterval)
            return
          }
          this.ai.img.left += this.interval.distance
          this.ai.info.left -= this.interval.distance
          this.ai.opacity += this.interval.opacity
        }, this.interval.time)
      } else if (direction == 'up') {
        this.aiInterval = setInterval(() => {
          if (this.ai.img.left <= this.ai.img.start) {
            clearInterval(this.aiInterval)
            return
          }
          this.ai.img.left -= this.interval.distance
          this.ai.info.left += this.interval.distance
          this.ai.opacity -= this.interval.opacity
        }, this.interval.time)
      }
    },
    /**
     执行动画--八大训练模式
     @param {String} direction 页面滚动方向
     @return
     */
    animationPattern(direction) {
      this.pattern.direction = direction
      if (direction == 'down') {
        this.patternInterval = setInterval(() => {
          if (this.pattern.img.left <= this.pattern.img.end) {
            clearInterval(this.patternInterval)
            return
          }
          this.pattern.img.left -= this.interval.distance
          this.pattern.info.left += this.interval.distance
          this.pattern.opacity += this.interval.opacity
        }, this.interval.time)
      } else if (direction == 'up') {
        this.patternInterval = setInterval(() => {
          if (this.pattern.img.left >= this.pattern.img.start) {
            clearInterval(this.patternInterval)
            return
          }
          this.pattern.img.left += this.interval.distance
          this.pattern.info.left -= this.interval.distance
          this.pattern.opacity -= this.interval.opacity
        }, this.interval.time)
      }
    },
    /**
     执行动画--智能检测系统
     @param {String} direction 页面滚动方向
     @return
     */
    animationMonitor(direction) {
      this.monitor.direction = direction
      if (direction == 'down') {
        this.monitorInterval = setInterval(() => {
          if (this.monitor.img.left >= this.monitor.img.end) {
            clearInterval(this.monitorInterval)
            return
          }
          this.monitor.img.left += this.interval.distance
          this.monitor.info.left -= this.interval.distance
          this.monitor.opacity += this.interval.opacity
        }, this.interval.time)
      } else if (direction == 'up') {
        this.monitorInterval = setInterval(() => {
          if (this.monitor.img.left <= this.monitor.img.start) {
            clearInterval(this.monitorInterval)
            return
          }
          this.monitor.img.left -= this.interval.distance
          this.monitor.info.left += this.interval.distance
          this.monitor.opacity -= this.interval.opacity
        }, this.interval.time)
      }
    },
    /**
     执行动画--OTA技术
     @param {String} direction 页面滚动方向
     @return
     */
    animationOta(direction) {
      this.ota.direction = direction
      if (direction == 'down') {
        this.otaInterval = setInterval(() => {
          if (this.ota.img.left <= this.ota.img.end) {
            clearInterval(this.otaInterval)
            return
          }
          this.ota.img.left -= this.interval.distance
          this.ota.info.left += this.interval.distance
          this.ota.opacity += this.interval.opacity
        }, this.interval.time)
      } else if (direction == 'up') {
        this.otaInterval = setInterval(() => {
          if (this.ota.img.left >= this.ota.img.start) {
            clearInterval(this.otaInterval)
            return
          }
          this.ota.img.left += this.interval.distance
          this.ota.info.left -= this.interval.distance
          this.ota.opacity -= this.interval.opacity
        }, this.interval.time)
      }
    },
  },
  watch: {
    '$parent.scrollTop': {
      handler(newVal, oldVal) {
        if (document.documentElement.clientWidth >= 1665) {
          this.bindScrollWindow(newVal)
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped>
$md-symbol-img-path: $md-symbol-img-path-before;

#banner {
  position: relative;
  height: 6.6rem;

  > .banner-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  > .banner-title {
    position: absolute;
    top: 2.08rem;
    right: 4.5rem;
    text-align: center;
    line-height: .71rem;
    font-size: .48rem;
    color: #fff;
  }
}

#detail {
  position: relative;
  overflow-x: hidden;

  > .detail-item {
    position: relative;
    height: 6.45rem;
    padding-bottom: 0.08rem;

    > .img {
      position: absolute;
    }

    > .info {
      position: absolute;
      width: 5.5rem;

      > .logo {
        position: absolute;
      }

      > .title {
        position: absolute;
        font-size: 0.44rem;
        font-weight: bold;
        line-height: 0.56rem;
      }

      > .subTitle {
        position: absolute;
        font-size: 0.32rem;
        color: #555;
        line-height: 0.45rem;
      }

      > .symbol {
        position: absolute;
        width: 0.44rem;
        height: 0.09rem;
        background-image: url('../' + $md-symbol-img-path);
      }

      > .desc {
        position: absolute;
        width: 4rem;
        font-size: 0.2rem;
        color: #888;
        line-height: 0.3rem;
      }
    }
  }

  > .detail-item.stim {
    background: #ffffff;

    > .img {
      top: 0.75rem;
      width: 6.4rem;
      height: 5rem;
    }

    > .info {
      > .logo {
        top: 1.53rem;
        left: 0;
        width: 1rem;
        height: 1.01rem;
      }

      > .title {
        top: 1.81rem;
        left: 0;
      }

      > .subTitle {
        top: 3.17rem;
        left: 0;
      }

      > .symbol {
        top: 3.92rem;
        left: 0;
      }

      > .desc {
        top: 4.13rem;
        left: 0rem;
      }
    }
  }

  > .detail-item.emg {
    background: #FAFBFF;

    > .img {
      top: 1.02rem;
      width: 5.32rem;
      height: 4.43rem;
    }

    > .info {
      > .logo {
        top: 1.54rem;
        left: 0;
        width: 1rem;
        height: 0.91rem;
      }

      > .title {
        top: 1.8rem;
        left: 0;
      }

      > .subTitle {
        top: 3.16rem;
        left: 0;
      }

      > .symbol {
        top: 3.91rem;
        left: 0;
      }

      > .desc {
        top: 4.12rem;
        left: 0;
      }
    }
  }

  > .detail-item.pressure {
    background: #ffffff;

    > .img {
      top: 1.01rem;
      width: 5rem;
      height: 4.43rem;
    }

    > .info {
      > .logo {
        top: 1.52rem;
        left: 0;
        width: 1rem;
        height: 0.98rem;
      }

      > .title {
        top: 1.71rem;
        left: 0;
      }

      > .subTitle {
        top: 3.07rem;
        left: 0;
      }

      > .symbol {
        top: 3.83rem;
        left: 0;
      }

      > .desc {
        top: 4.03rem;
        left: 0;
      }
    }
  }

  > .detail-item.ai {
    background: #FAFBFF;

    > .img {
      top: 0.7rem;
      width: 5.5rem;
      height: 5.2rem;
    }

    > .info {
      > .logo {
        top: 1.46rem;
        left: 0;
        width: 1rem;
        height: 1.07rem;
      }

      > .title {
        top: 1.67rem;
        left: 0
      }

      > .subTitle {
        top: 3.03rem;
        left: 0;
      }

      > .symbol {
        top: 3.89rem;
        left: 0;
      }

      > .desc {
        top: 4.09rem;
        left: 0;
      }
    }
  }

  > .detail-item.pattern {
    background: #ffffff;

    > .img {
      top: 1.2rem;
      width: 6.23rem;
      height: 4.08rem;
    }

    > .info {
      > .logo {
        top: 1.49rem;
        left: 0;
        width: 1rem;
        height: 1rem;
      }

      > .title {
        top: 1.74rem;
        left: 0;
      }

      > .subTitle {
        top: 3.1rem;
        left: 0;
      }

      > .symbol {
        top: 3.85rem;
        left: 0;
      }

      > .desc {
        top: 4.06rem;
        left: 0;
      }
    }
  }

  > .detail-item.monitor {
    background: #FAFBFF;

    > .img {
      top: 1.41rem;
      width: 5.77rem;
      height: 4.14rem;
    }

    > .info {
      > .logo {
        top: 1.5rem;
        left: 0;
        width: 0.82rem;
        height: 1rem;
      }

      > .title {
        top: 1.73rem;
        left: 0;
      }

      > .subTitle {
        top: 3.09rem;
        left: 0;
      }

      > .symbol {
        top: 3.85rem;
        left: 0;
      }

      > .desc {
        top: 4.05rem;
        left: 0;
      }
    }
  }

  > .detail-item.ota {
    background: #ffffff;

    > .img {
      top: 1.31rem;
      width: 6.27rem;
      height: 3.83rem;
    }

    > .info {
      > .logo {
        top: 1.29rem;
        left: 0;
        width: 1rem;
        height: 1.17rem;
      }

      > .title {
        top: 1.64rem;
        left: 0;
      }

      > .subTitle {
        top: 3rem;
        left: 0;
      }

      > .symbol {
        top: 3.76rem;
        left: 0;
      }

      > .desc {
        top: 3.96rem;
        left: 0;
      }
    }
  }
}
</style>
